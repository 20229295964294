export enum LOGINTYPE {
  ANONYMOUS     = 'ANONYMOUS',
  WECHAT        = 'WECHAT',
  WECHAT_PUBLIC = 'WECHAT-PUBLIC',
  WECHAT_OPEN   = 'WECHAT-OPEN',
  CUSTOM        = 'CUSTOM',
  EMAIL         = 'EMAIL',
  USERNAME      = 'USERNAME',
  NULL          = 'NULL'
}
